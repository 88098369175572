import { useState, useEffect } from 'react'
import { Outlet, useLocation, useNavigate, Link } from 'react-router-dom'
import { AgentHeader, AgentNav } from '../../index'
import API from '../../../apis/index'
import './layout.css'
import constants from '../../../config/constants'
import MixPanel from '../../../config/mixpanel'
import utils from '../../../config/utils'
import { RxHamburgerMenu } from "react-icons/rx"

const Layout = (props) => {
    const location = useLocation()
    const [currentLocation, setCurrentLocation] = useState(location.pathname.toLowerCase())
    const navigate = useNavigate()
    const [navCss, setNavCss] = useState("hidden")
    const [navSeed, setNavSeed] = useState(null)
    const [bannerText, setBannerText] = useState(null)
    const [bannerLink, setBannerLink] = useState(null)
    const [expanded, setExpanded] = useState(true)

    // setTimeout(() => setExpanded(!expanded), 4000)

    useEffect(() => {
        setCurrentLocation(location.pathname.toLowerCase())
    }, [location])

    useEffect(() => {
        API.auth.checkAsync((loggedIn) => {
            if (loggedIn === false) {
                navigate(props.base + "/auth")
            } else {
                const { user_type: backendType, user_id, wallet_balance: walletBalance, permissions, initials, show_tour, promotional_header } = loggedIn
                if (backendType === "backoffice" && props.base !== "/backoffice") {
                    window.location.replace("/backoffice")
                    return
                } else if (backendType === "agent" && props.base !== "/agent") {
                    window.location.replace("/agent")
                    return
                }
                const storedType = window.sessionStorage.getItem(constants.SessionVariables.UserType)
                if (storedType !== backendType) {
                    window.sessionStorage.setItem(constants.SessionVariables.UserType, backendType)
                }
                window.sessionStorage.setItem(constants.SessionVariables.Permissions, permissions)
                window.sessionStorage.setItem(constants.SessionVariables.UserInitials, initials)
                utils.updateSessionVar(constants.SessionVariables.WalletBalance, walletBalance)
                utils.updateSessionVar(constants.SessionVariables.UserInitials, initials)
                MixPanel.identify("user_" + user_id)
                MixPanel.people.set_once({ type: backendType })

                // show spotlight tour
                if (backendType === "agent") {
                    if (show_tour === true) {
                        const script = document.createElement('script');
                        script.src = "/assets/scripts/welcome-tour.js";
                        document.body.appendChild(script);
                    }

                }

                // show promotional text banner
                if (promotional_header && typeof promotional_header === "object" && promotional_header?.text) {
                    setBannerText(promotional_header?.text)
                    if (promotional_header?.cta) setBannerLink(promotional_header?.cta)
                }

                setNavSeed(Date.now())
            }
        })
    }, [navigate, props.base])

    return (
        <div className="flex flex-row items-stretch relative">
            <AgentNav className={"flex-none md:flex absolute md:relative left-0 top-0 w-full min-h-full z-40 bg-sk-blue transition-all duration-300 ease-in-out " + (expanded ? "md:w-80" : "md:w-20") + " " + navCss} currentLocation={currentLocation} base={props.base} closeNav={() => setNavCss("hidden")} seed={navSeed} expanded={expanded} toggleExpanded={() => setExpanded(!expanded)} />
            <div className="flex-1 flex flex-col">
                {props.ribbon && bannerText && <div className="bg-sk-yellow text-black py-2 text-center text-sm font-medium">
                    {bannerLink ?
                        <Link to={bannerLink} className="underline underline-offset-2" dangerouslySetInnerHTML={{ __html: bannerText }}></Link>
                        : <p className="" dangerouslySetInnerHTML={{ __html: bannerText }}></p>}
                </div>}
                <AgentHeader className="flex-0 hidden md:flex" base={props.base} notifications={props.base === "/agent" ? false : false} wallet={props.base === "/agent" ? true : false} />
                <div id="sk-nav-hamburger-icon" className="flex-1 relative py-3 mx-8 md:mx-12">
                    <RxHamburgerMenu className="md:hidden absolute top-4 left-0" onClick={() => setNavCss("flex")} />
                    <Outlet />
                </div>
                <div className="flex-none py-2">
                    <p className="text-center md:text-right text-xs *:px-1 font-thin mr-2">
                        <Link target="_blank" to="https://sankash.in/assets/documents/terms-of-use.pdf"><span className="select-none">Terms of Use</span></Link>
                        <span>|</span>
                        <Link target="_blank" to="https://sankash.in/assets/documents/merchant-terms.pdf"><span className="select-none">Merchant Terms</span></Link>
                        <span>|</span>
                        {/* <Link target="_blank" to="terms-of-use.pdf"><span className="select-none">FAQ</span></Link>
                        <span>|</span> */}
                        <Link target="_blank" to="https://sankash.in/assets/documents/information-security-policy.pdf"><span className="select-none">IT Security Policy</span></Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Layout